import ScrollBar from '../components/scrollBar'
import AccountTable from '../components/accountTable'
import notBillTable from '../components/notBillTable'
import web_credit_creditAccountDetail from '@/lib/data-service/default/web_credit_creditAccountDetail'
export default {
    data() {
        return {
            numInfo: { numShow: true, numText: '**** **' },
            defaultText: '--',
            detailData: {},
            flowList: [],
            billList: []
        }
    },
    methods: {
        getInfo() {
            web_credit_creditAccountDetail().then(res => {
                this.detailData = res
                this.flowList = res.creditFlowResults.pageData
                this.billList = res.creditBillResults.pageData
            })
        },
        repayment() {
            this.$router.push({ name: 'distributor-credit-back-payment', query: { type: 1 } })
        }
    },
    activated() {
        this.getInfo()

    },
    components: { ScrollBar, AccountTable, notBillTable }
}
