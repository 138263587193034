//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data(){
        return{
            thList:['流水号','业务单号','交易类型','金额','交易时间','操作人'],
        }
    },
    props:{
        tableData:{
            type:Array,
            default:[]
        }
    }
}
